<template>
  <div class="mobile-size">
    <div class="welcome-card">
      <img
        class="px-1 respon-img"
        :src="require(`@/assets/welcome-card.png`)"
        alt
        style="width: 668px"
      />
      <div class="head-title">
        <span> Notification </span>
      </div>
      <div class="Welcome">
        <span class="text-title"> Welcome G-Space </span>
      </div>
      <div class="row detail">
        <div class="col-12" align="left">
          <span class="title">Pre TestGame</span>
        </div>
        <div class="col-12 mt-3 responMargin text-height" align="left">
          <span class="text">
            Pay attention to the filter change schedule in your RO system's
            owner's manual.
          </span>
        </div>
        <div class="col-12 mt-3" align="left">
          <span class="title">BNB TO G-space</span>
        </div>
        <div class="col-12 mt-3 responMargin text-height" align="left">
          <span class="text">
            &emsp;&emsp; xxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxx xxxxxxxxxxxx
            xxxxxxxxxxxxxxx xxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxx
            xxxxxxxx xxxxxxxxxxx xxxxxxxxxxxxx xxxxxxxxxxxxxxxxxx
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxx
          </span>
        </div>
      </div>
    </div>

    <div class="metamask-btn">
      <button class="btn" @click="login()">
        <img src="@/assets/metamask.png" alt="g-space" class="img-fluid" />
      </button>
    </div>
  </div>
</template>

<script>
import Wallet from '@/contracts/scripts/Wallet.js'
import Config from '@/config'

import Swal from 'sweetalert2'

export default {
  props: {
    onLoginSuccess: { type: Function },
  },
  data() {
    return {
      chainIdHex: '0x61'
    };
  },

  methods: {
    async login() {
      console.log("Login Matamask ");

      if (window["ethereum"]) {

        const connection = await Wallet.connectWallet();

        if (connection) {
          window["ethereum"].on("accountsChanged", (accounts) => {
            if (accounts.length == 0) {
              // console.log("Account disconnected.");
            }
          });

          const currentChainID = await Wallet.getChainID();

          if (currentChainID != Config.TESTNET_CONTRACT.CHAIN_ID) {
            try {

              await this.switchEthereumChain()
              this.onLoginSuccess()

            } catch (switchError) {
              console.log("switchError ", switchError);
              // This error code indicates that the chain has not been added to MetaMask.
              if (switchError.code === 4902) {
                try {
                  await window["ethereum"].request({
                    method: "wallet_addEthereumChain",
                    params: [
                      {
                        chainName: Config.TESTNET_CONTRACT.NETWORK_NAME,
                        rpcUrls: [Config.TESTNET_CONTRACT.RPC_URL],
                        chainId: "0x61",
                        nativeCurrency: {
                          name: Config.TESTNET_CONTRACT.CURRENCY_SYMBOL,
                          symbol: Config.TESTNET_CONTRACT.CURRENCY_SYMBOL,
                          decimals: 18,
                        },
                        blockExplorerUrls: [Config.TESTNET_CONTRACT.BLOCK_EXPLORER_URL],
                      },
                    ],
                  });

                  // await this.switchEthereumChain()
                } catch (addError) {

                  const { message } = addError

                  Swal.fire({
                    title: 'Error!',
                    text: `${message}`,
                    icon: 'error',
                    confirmButtonText: '&nbsp;&nbsp;OK&nbsp;&nbsp;'
                  })

                  console.log(addError);
                  return
                }
              }

              if (switchError.code !== 4001)
                this.onLoginSuccess()
                
              return
            }


          } else {
            console.log('login success')
            this.onLoginSuccess()

          }
        }
      } else {

        console.log("MetaMask is not install !!!");

        const toast = instantiate(this.errorToast);
        toast.getComponent(ErrorToast).init("MetaMask is not install !!!");
        toast.setParent(this.toastRoot);
      }

      window["ethereum"].on("accountsChanged", (accounts) => {
        console.log("changing accounts");
        window["ethereum"].request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: this.chainIdHex }],
        });
        
      });

    },

    async switchEthereumChain() {
      return await window['ethereum'].request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: this.chainIdHex }],
      });
    }

  },
};
</script>

<style lang="scss" scoped>
.mobile-size {
  padding: 3rem;
}
.welcome-card {
  margin: 0 auto;
  text-align: center;
  position: relative;
  width: 668px;
}
.head-title {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 17px;
  left: 0;
  right: 0;

  span {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
  }
}
.Welcome {
  width: 668px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 75px;
  left: 0;
  right: 0;
  .text-title {
    font-weight: 700;
    font-size: 50px;
    color: #00b2ff;
  }
}
.detail {
  width: 668px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 180px;
  padding: 0 70px;

  span {
    color: #fff;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
  }
  .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
  }
}

.metamask-btn {
  text-align: center;
  margin: 70px auto;
  .btn {
    background: transparent;
    padding: 0;
  }
}

@media only screen and (max-width: 912px) {
  .respon-img {
    width: 508px !important;
  }
  .welcome-card {
    width: 508px !important;
  }
  .head-title {
    span {
      color: #fff;
      font-weight: 700;
      font-size: 16px !important;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -5px;
      left: 0;
      right: 0;
    }
  }
  .Welcome {
    width: 508px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 55px;
    left: 0;
    right: 0;
    .text-title {
      font-weight: 700;
      font-size: 40px;
      color: #00b2ff;
    }
  }
  .detail {
    width: 508px !important;

    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 120px !important;

    span {
      color: #fff;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
    }
    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .respon-img {
    width: 408px !important;
  }
  .welcome-card {
    width: 408px !important;
  }
  .head-title {
    span {
      color: #fff;
      font-weight: 700;
      font-size: 16px !important;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -10px;
      left: 0;
      right: 0;
    }
  }
  .Welcome {
    width: 408px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40px;
    left: 0;
    right: 0;
    .text-title {
      font-weight: 700;
      font-size: 28px;
      color: #00b2ff;
    }
  }
  .detail {
    width: 408px !important;
    padding: 0 50px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 100px !important;

    span {
      color: #fff;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
    }
    .text {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }
  }
  .responMargin {
    margin-top: 0px !important;
  }
  .text-height {
    line-height: 20px;
  }
  .img-fluid {
    width: 200px;
  }
}
@media only screen and (max-width: 520px) {
  .respon-img {
    width: 320px !important;
  }
  .welcome-card {
    width: 320px !important;
  }
  .head-title {
    span {
      color: #fff;
      font-weight: 700;
      font-size: 12px !important;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -12px;
      left: 0;
      right: 0;
    }
  }
  .Welcome {
    width: 320px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 35px;
    left: 0;
    right: 0;
    .text-title {
      font-weight: 700;
      font-size: 20px;
      color: #00b2ff;
    }
  }
  .detail {
    width: 320px !important;
    padding: 0 30px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 70px !important;

    span {
      color: #fff;
    }
    .title {
      font-weight: 700;
      font-size: 12px;
    }
    .text {
      font-weight: 400;
      font-size: 8px;
    }
  }
  .responMargin {
    margin-top: 0px !important;
  }
  .text-height {
    line-height: 10px;
  }
  .img-fluid {
    width: 180px;
  }
  .metamask-btn {
    margin: 30px auto;
  }
}
@media only screen and (max-width: 430px) {
  .respon-img {
    width: 280px !important;
  }
  .welcome-card {
    width: 280px !important;
  }
  .head-title {
    span {
      color: #fff;
      font-weight: 700;
      font-size: 10px !important;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -11px;
      left: 0;
      right: 0;
    }
  }
  .Welcome {
    width: 280px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30px;
    left: 0;
    right: 0;
    .text-title {
      font-weight: 700;
      font-size: 18px;
      color: #00b2ff;
    }
  }
  .detail {
    width: 280px !important;
    padding: 0 25px !important;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 60px !important;

    span {
      color: #fff;
    }
    .title {
      font-weight: 700;
      font-size: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 7px;
    }
  }
  .responMargin {
    margin-top: 0px !important;
  }
  .text-height {
    line-height: 10px;
  }
  .img-fluid {
    width: 150px;
  }
  .metamask-btn {
    margin: 30px auto;
  }
}
@media only screen and (max-width: 384px) {
  .mobile-size {
    padding: 50px 30px !important;
  }
  .img-fluid {
    width: 120px;
  }
}
@media only screen and (max-width: 360px) {
  .mobile-size {
    padding: 50px 10px !important;
  }
}
</style>
