import web3 from 'web3';
import Contract from '../atrifacts/GSpace.sol/GSpace.json'
import GSPContract from '../atrifacts/GSP.sol/GSP.json'
import GspaceTestnet from '../atrifacts/GspaceTestnet.sol/GSpaceTestnet.json'



export class Wallet {

    constructor() {
    
        this.zero = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

        this.GSPAddress = "0x6C2994328a3035427CD7003F850A79f4DD1c4cfC";
        this.HeroAddress = "0x168f4Af2c62967Cab480669fcC082A689b5aCbc7";
        this.EquipmentAddress = "0xfaf2CC9f8c7A0e2F51b5A5BEA325D312F313049a";
        this.ItemAddress = "0xCFCdAD10Dcb74291746d017c679D6307d877b7bB";
        this.PetAddress = "0x64017d268CCdb6dF9010389097c36D6C923A8bcE";
        this.GSAddress = "0x8087673B9d2423E8CF92BBfdc313E041152E04E6";
        this.ChainId_hex =  '0x61';
        

        this.accounts = null
        this.address = null
        this.providers = null
        this.signer = null
    
        this.gspCoinToken = null
        this.gspContract = null
        this.heroContract = null

    }

    async connectWallet() {

        try {

            this.providers = new web3(window['ethereum'])
            const [ address ] = await this.providers.eth.requestAccounts()
            this.address = address

            this.gspCoinToken  = new this.providers.eth.Contract(GSPContract.abi, this.GSPAddress)
            this.gspContract = new this.providers.eth.Contract(Contract.abi, this.GSAddress)
            this.gspaceTestnetContract = new this.providers.eth.Contract(GspaceTestnet.abi, this.GSAddress)

            return Promise.resolve(this.providers)

        } catch (error) {

            return Promise.reject(error)
        }
    }

    
    async getChainID() {
        const result = await this.providers.eth.getChainId()
        return result
    }

    async exchangeToGsp() {

        try {

            const result = await this.gspaceTestnetContract.methods.ConvertEthToGsp().send({
                from: this.address
            })

            return Promise.resolve(result)

        } catch (error) {

            return Promise.reject(error)
        }
    }

}

export default new Wallet()