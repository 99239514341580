<template>
  <b-overlay :show="show" :variant="variant" :opacity="1" rounded="sm">
    <div class="exchange">
      <div class="header">
        <div class="banner">
          <img src="@/assets/logo.png" alt="g-space" class="img-fluid" />
        </div>
        <div class="reports">
          <ul>
            <li>
              <router-link to="/">TestNet</router-link>
            </li>
            <li>
              <router-link to="/">BNB Exchange</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <template v-if="!isLogin"> <Welcome :onLoginSuccess="onLoginSuccess" /> </template>
          <template v-else>
            <Exchange />
          </template>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import Exchange from "../components/Exchange.vue";
import Welcome from "../components/Welcome.vue";

export default {
  name: "ExchangeView",
  components: {
    Exchange,
    Welcome,
  },
  data() {
    return {
      isLogin: false,
      show: false,
      variant: 'transparent'
    };
  },
  mounted() {
    //
  },
  methods: {
    
    onLoginSuccess() {
      this.isLogin = true
    }
  },
};
</script>

<style lang="scss" scoped>
.exchange {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-position: center;
  background-image: url("@/assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .content {
    min-height: 79vh;
    height: 100%;
    background: rgb(5 16 34 / 90%);
  }
}
</style>
