<template>
  <div class="home">
    <div class="header">
      <div class="banner">
        <img src="@/assets/logo.png" alt="g-space" class="img-fluid" />
      </div>
      <div class="reports">
        <ul>
          <li>
            <router-link to="/">G-Space</router-link>
          </li>
          <li>
            <router-link to="/">G-Space Coin</router-link>
          </li>
          <li>
            <router-link to="/">Claim Ally</router-link>
          </li>
          <li>
            <router-link to="/">Claim Air Lock</router-link>
          </li>
          <li>
            <router-link to="/">Cliam Token</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid">
      <div class="home">
        <div class="gs-box">
          <div class="input-search">
            <input
              type="search"
              placeholder="Search wallet address"
              v-model="address"
              @keyup="getLists()"
            />
            <span>
              <img src="@/assets/search.svg" alt width="16px" />
            </span>
          </div>
        </div>
        <div class="gs-box">
          <div class="responsive">
            <table class="table-report" cellspacing="0" cellpadding="0">
              <thead>
                <tr>
                  <th>Time Claim</th>
                  <th>Wallet Address</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Tx Hash</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in lists" :key="index">
                  <td class="nowrap">
                    {{
                    new Date(item.created_at * 1000).toLocaleString("en-GB")
                    }}
                  </td>
                  <td class="nowrap">{{ item.address }}</td>
                  <td class="nowrap">{{ formatMoney(item.amount / 1000000000000000000, 4) }}</td>
                  <td>
                    <span class="status success" v-if="item.confirmation >= 15">Successful</span>
                    <span class="status panding" v-else>Panding</span>
                  </td>
                  <td>
                    <a
                      :href="`http://43.229.132.113:5100/tx/${item.hash}`"
                      target="_blank"
                    >{{ item.hash }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="paginate">
            <b-pagination-nav
              v-model="currentPage"
              :number-of-pages="pages"
              base-url="#"
              first-number
              last-number
              @input="getLists()"
            ></b-pagination-nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    HelloWorld
  },
  data() {
    return {
      lists: [],
      from: 0,
      limit: 20,
      currentPage: 1,
      pages: 1,
      address: ""
    };
  },
  mounted() {
    this.getLists();
  },
  methods: {
    getLists() {
      console.log('0xe10B09Bd4442E707701DC7C09940cE34C705Be83')
      try {
        const params = {
          page: (this.currentPage - 1) * this.limit,
          limit: this.limit,
          address: this.address
        };
        axios
          .get(`https://api-gspace.ez-staging.com/api/transactions`, { params })
          .then(({ data }) => {
            this.lists = data.data;
            this.paginate = data.paginate;
            if (data.paginate) {
              this.pages = Math.ceil(data.paginate.total / this.limit);
            }
          })
          .catch(error => {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error.message);
      }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {}
    }
  }
};
</script>

<style lang="scss" scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 14px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 14px;
}
.gs-box {
  background: #ffffff;
  /* Stroke Color */

  border: 1px solid #eff0f6;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 22px 20px;
  margin-bottom: 20px;
}
.input-search {
  position: relative;
  height: 35px;
  input {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 35px;
    padding: 6px 6px 6px 34px;
    outline: 0;
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
span {
  &.status {
    height: 28px;
    padding: 3px 10px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    &.success {
      background: #6fcf97;
    }
    &.fail {
      background: #ec8686;
    }
    &.panding {
      color: #000;
      background: #ffc107;
    }
  }
}
.responsive {
  overflow: scroll;
}
table {
  &.table-report {
    width: 100%;
    border: 0;
    tr,
    th,
    td {
      border: 0;
      vertical-align: middle;
      border-collapse: 0;
      &.nowrap {
        white-space: nowrap;
      }
    }
    thead {
      border-radius: 5px;
      overflow: hidden;
      th {
        white-space: nowrap;
        background-color: #f9f9f9;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        color: #4f4f4f;
        padding: 9px 10px;
        text-align: left;
      }
    }
    tbody {
      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        color: #828282;
        padding: 9px 10px;
        a {
          text-decoration: none;
          color: #2f80ed;
        }
      }
    }
  }
}
.paginate {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
</style>
