export default class ENV {

    static get TESTNET_CONTRACT() {
        return {
            "NETWORK_NAME": "Smart Chain - Testnet",
            "RPC_URL": "https://data-seed-prebsc-1-s1.binance.org:8545",
            "CHAIN_ID": 97,
            "CURRENCY_SYMBOL": "BNB",
            "BLOCK_EXPLORER_URL": "https://testnet.bscscan.com"
        }
    }
    
    static get CONFIG_API() {
        return {
            "BASE_URL": "https://api-gspace.ez-staging.com/api",
            "APP_TOKEN": "aaaa",
        }
    }

    static get EXCHANGE_RATE() {
        return {
            "AMOUNT_LIST": [1, 5, 10, 50, 100, 500],
            "GOLD_RATE": 1000000,
        }
    }

    static get CLAIM_MIN() {
        return 1
    }

}