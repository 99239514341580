<template>
  <div class="row mx-0">
    <!-- <div class="col-12 px-0 py-4">
      <div class="d-flex justify-content-center">
        <h3 class="text-light m-0">Total G-space coin</h3>
      </div>
    </div> -->
    <!-- <div class="col-12 px-0 py-2">
      <div class="d-flex justify-content-center w-100">
        <div
          v-for="(item, index) in splitTotalCoin(totalCoin)"
          :key="index"
          class="card-coin"
        >
          <img
            src="@/assets/card-item-coin.png"
            alt="card-g-space-coin"
            class="img-fluid"
          />
          <div class="coin">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 py-4">
      <div class="d-flex justify-content-between">
        <div class="sold-out">Sold out</div>
        <div class="balance">Balance</div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="amount">{{ soldoutAmount }}</div>
        <div class="amount">{{ balanceAmount }}</div>
      </div>
    </div>
    <div class="col-12 px-0">
      <b-progress height="0.5rem" :max="soldoutAmount + balanceAmount">
        <b-progress-bar
          :value="soldoutAmount"
          variant="sold-out"
        ></b-progress-bar>
      </b-progress>
    </div> -->
    <div class="col-12 px-0 py-5">
      <div class="d-flex justify-content-center w-100">
        <div class="card-exchange">
          <img
            src="@/assets/bg-card-exchange.png"
            alt="bg-card-exchange"
            class="img-fluid"
          />
          <div class="exchange">
            <div class="d-flex justify-content-start w-100">
              <h3 class="text-light m-0">Exchange</h3>
            </div>
            <div class="w-100">
              <div class="row mx-0">
                <div class="col-5 px-0">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-content-center py-2">
                      <h5 class="m-0 text-light">BNB</h5>
                    </div>
                    <div class="d-flex justify-content-center">
                      <img
                        src="@/assets/bnb-coin.png"
                        alt="bnb-coin"
                        width="50%"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-2 px-0"></div>
                <div class="col-5 px-0">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-content-center py-2">
                      <h5 class="m-0 text-light">G-Space</h5>
                    </div>
                    <div class="d-flex justify-content-center">
                      <img
                        src="@/assets/gspace-coin.png"
                        alt="gspace-coin"
                        width="50%"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-5 px-0 pt-3 pb-2">
                  <b-form-input
                    v-model="bnbAmount"
                    type="number"
                    class="input-exchange"
                    size="lg"
                  ></b-form-input>
                </div>
                <div
                  class="
                    col-2
                    px-0
                    pt-3
                    pb-2
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <img src="@/assets/subtract.png" alt="subtract" width="25%" />
                </div>
                <div class="col-5 px-0 pt-3 pb-2">
                  <b-form-input
                    v-model="gspaceAmount"
                    type="number"
                    class="input-exchange"
                    size="lg"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                h-100
              "
            >
              <b-button variant="exchange" size="lg" @click="onSubmit()"
                >Exchange</b-button
              >
              <span class="small text-light mt-1">*One time per wallet</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wallet from "@/contracts/scripts/Wallet.js"
import Config from '@/config'

import Swal from 'sweetalert2'

export default {
  name: "ExchangeComponent",
  data() {
    return {
      totalCoin: "10000",
      soldoutAmount: 1114470,
      balanceAmount: 2401290,
      bnbAmount: 1,
      gspaceAmount: 100,
    };
  },
  mounted() {
    //
  },
  methods: {
    splitTotalCoin(totalCoin) {
      try {
        return totalCoin.split("");
      } catch (error) {
        return ["0", "0", "0", "0", "0"];
      }
    },
    async onSubmit() {
      console.log("SUCCESS");

      try {

        const result = await Wallet.exchangeToGsp()
        console.log(result)
        this.onExchangeSuccess()

      } catch (addError) {

        const { message } = addError

        Swal.fire({
          title: 'Error!',
          text: `${message}`,
          icon: 'error',
          confirmButtonText: '&nbsp;&nbsp;OK&nbsp;&nbsp;'
        })

      }
      
    },

    onExchangeSuccess() {
      Swal.fire({
          title: 'Success!',
          text: `transaction has been successfully`,
          icon: 'success',
          confirmButtonText: '&nbsp;&nbsp;OK&nbsp;&nbsp;'
        })
    }

  },
};
</script>

<style lang="scss" scoped>
.card-coin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  padding: 0.5rem;

  .coin {
    position: absolute;
    font-size: 3.25rem;
    font-weight: 700;
    color: #ffffff;
  }
}
.sold-out {
  font-weight: 600;
  font-size: 1.25rem;
  color: #ff5f00;
}
.balance {
  font-weight: 600;
  font-size: 1.25rem;
  color: #00dfff;
}
.amount {
  font-weight: 600;
  font-size: 1.35rem;
  color: #ffffff;
}
.bg-sold-out {
  background: linear-gradient(90deg, #ff8a00 0.08%, #ff0000 100.08%) !important;
}
.progress {
  background: linear-gradient(90deg, #0075ff 0.08%, #00e0ff 100.08%) !important;
}
.card-exchange {
  position: relative;
  display: flex;
  opacity: 1;

  .exchange {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0.75rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .input-exchange {
      border: 1px solid #1a1a1a;
      background: #1a1a1a;
      border-radius: 5px;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      &:focus {
        box-shadow: none;
        border: 1px solid #ffffff;
      }
      &:hover {
        border: 1px solid #ffffff;
      }
    }

    .btn-exchange {
      border: none;
      background: linear-gradient(
        180deg,
        #00ff29 0%,
        #13ed00 8.42%,
        #226b00 89.96%,
        #03a300 100%
      );
      border-radius: 5px;
      color: #ffffff;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background: linear-gradient(
          180deg,
          #07fd30 0%,
          #44f834 8.42%,
          #247200 89.96%,
          #06b803 100%
        );
      }
    }
  }
}
</style>
